import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Globals } from "app/globals";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LineasService {
  constructor(private http: HttpClient, private globals: Globals) {}
  get(filter?: any) {
    if (!filter) filter = {};
    filter = JSON.stringify(filter);
    return this.http
      .get(`${this.globals.apiUrl}/lineas/get`, { params: { filter } })
      .pipe(catchError(this.handleError));
  }

  agregar(linea) {
    return this.http
      .post(`${this.globals.apiUrl}/lineas/agregar`, {linea})
      .pipe(catchError(this.handleError));
  }

  editar(linea) {
    return this.http
      .post(`${this.globals.apiUrl}/lineas/editar`, {linea})
      .pipe(catchError(this.handleError));
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
