import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Globals } from "./../globals";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ColoresService {
  constructor(private http: HttpClient, private globals: Globals) {}

  getAll() {
    return this.http
      .get(`${this.globals.apiUrl}/colores/getAll`)
      .pipe(catchError(this.handleError));
  }

  getColoresDeBat() {
    return this.http
      .get(`${this.globals.apiUrl}/colores/getColoresDeBat`)
      .pipe(catchError(this.handleError));
  }

  agregar(color) {
    return this.http
      .post(`${this.globals.apiUrl}/colores/add`, color)
      .pipe(catchError(this.handleError));
  }

  addAndReturnRows(color) {
    return this.http
      .post(`${this.globals.apiUrl}/colores/addAndReturnRows`, {
        color: color,
      })
      .pipe(catchError(this.handleError));
  }

  editar(color) {
    return this.http
      .post(`${this.globals.apiUrl}/colores/edit`, {
        color: color,
      })
      .pipe(catchError(this.handleError));
  }

  eliminar(id) {
    return this.http
      .post(`${this.globals.apiUrl}/colores/delete`, {
        id: id,
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
