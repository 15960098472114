import {
  AfterViewInit,
  Component,
  Inject,
  Injectable,
  InjectionToken,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { AbstractControl, FormBuilder, Validators } from "@angular/forms";
import { TableColumn } from "@swimlane/ngx-datatable";
import { ToastrManager } from "ng6-toastr-notifications";
import { NgxSmartModalService } from "ngx-smart-modal";
import { BehaviorSubject, of, Subject } from "rxjs";
import { switchMap, takeUntil, finalize } from "rxjs/operators";

declare type actions = "add" | "edit" | "delete";

export interface DataI {
  get: any;
  update: any;
  add: any;
  delete: any;
}

export const DATA_SERVICE = new InjectionToken<DataI>("data.service");

@Component({
  template: "",
})
export abstract class BaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  constructor(
    @Inject(DATA_SERVICE) public _data: DataI,
    public _fb: FormBuilder,
    public _modal: NgxSmartModalService,
    public _toast: ToastrManager
  ) {}

  public onDestroy$ = new Subject<void>();

  //Req
  public data$ = () =>
    of({}).pipe(
      switchMap(() => {
        this.loading = true;
        return this._data.get(this.filters);
      }),
      takeUntil(this.onDestroy$),
      finalize(() => (this.loading = false))
    );
  public add$ = (data: any) =>
    of({}).pipe(
      switchMap(() => {
        if (this.form.invalid) throw "Formulario inválido.";
        this.requesting = true;
        return this._data.add(data);
      }),
      takeUntil(this.onDestroy$),
      finalize(() => {
        this.requesting = false;
      })
    );
  public update$ = (data: any) =>
    of({}).pipe(
      switchMap(() => {
        this.requesting = true;
        return this._data.update(data);
      }),
      takeUntil(this.onDestroy$),
      finalize(() => {
        this.requesting = false;
      })
    );
  public delete$ = (id: string | number) =>
    of({}).pipe(
      switchMap(() => {
        this.requesting = true;
        return this._data.delete(id);
      }),
      takeUntil(this.onDestroy$),
      finalize(() => {
        this.requesting = false;
      })
    );

  //Form
  public action$ = new BehaviorSubject<actions>(null);
  public form = this._fb.group({});
  public filtersForm = this._fb.group({});

  //Data
  public model: string = "";
  public models: string = "";
  public pronombre: "a" | "o" = "o";
  public data: any[] = [];
  public filteredData: any[] = [];
  public loading: boolean = false;
  public requesting: boolean = false;
  public mapData = (data: any[]) => {
    return data;
  };

  //Table
  public selectedRows: any[] = [];
  public selectedRow: any;
  public columns: TableColumn[] = [];

  //Getters
  get action() {
    return this.action$.value;
  }

  get disabled() {
    if (this.action === "add" || this.action === "edit")
      return this.form.invalid || this.requesting;
    else this.requesting;
  }

  get filters() {
    let filters = { where: { status: "activo" } };
    return filters;
  }

  //Cycles
  ngOnInit() {
    this.getData();
  }

  ngAfterViewInit(): void {
    this.initListeners();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  //Listeners
  initListeners() {
    //On Action Change
    this.onActionChange();
  }

  onActionChange() {
    this.action$.pipe(takeUntil(this.onDestroy$)).subscribe(
      (action) => {
        if ((action === "delete" || action === "edit") && !this.selectedRow) {
          this._toast.errorToastr(
            `Seleccione un${this.pronombre === "a" ? this.pronombre : ""} ${
              this.model
            } para ${action === "delete" ? "eliminar" : "editar"}`,
            "Error"
          );
          return;
        }
        this.open(action);
      },
      (err) => this._toast.errorToastr(err, "Error")
    );
  }

  //Req Data
  getData() {
    this.data$().subscribe(
      (data: any[]) => {
        this.data = this.mapData(data);
        this.filteredData = this.data;
      },
      (err) => {
        this._toast.errorToastr(
          `Error al obtener ${this.models}, verifica tu conexión de internet`
        );
      }
    );
  }

  //Modals & Form
  open(action: actions) {
    if (action === "add") {
      this._modal.get("manageModal").open();
    }
    if (action === "edit") {
      this.form.patchValue(this.selectedRow);
      this._modal.get("manageModal").open();
    }
    if (action === "delete") {
      this.form.patchValue(this.selectedRow);
      this._modal.get("deleteModal").open();
    }
  }

  close() {
    if (this.action$.value === "delete") this._modal.get("deleteModal").close();
    else {
      this._modal.get("manageModal").close();
    }
    this.form.reset();
  }

  reset() {
    this.form.reset();
    this.selectedRows = [];
    this.selectedRow = undefined;
  }

  onSubmit() {
    if (this.action === "add") this.add();
    if (this.action === "delete") this.delete();
    if (this.action === "edit") this.edit();
  }

  //Request Actions
  add() {
    const data = this.cleanObj(this.form.value);
    this.add$(data).subscribe(
      (res) => {
        this._toast.successToastr(
          `${this.model} agregad${this.pronombre} correctamente.`
        );
        this.close();
        this.reset();
        this.getData();
      },
      (err) => {
        this._toast.errorToastr(err, "Error");
      }
    );
  }

  edit() {
    const data = {
      ...this.cleanObj(this.form.value),
      id: this.selectedRow.id,
    };
    this.update$(data).subscribe(
      (res) => {
        this._toast.successToastr(
          `${this.model} editad${this.pronombre} correctamente.`
        );
        this.close();
        this.reset();
        this.getData();
      },
      (err) => {
        this._toast.errorToastr(err, "Error");
      }
    );
  }

  delete() {
    this.delete$(this.selectedRow.id).subscribe(
      (res) => {
        this._toast.successToastr(
          `${this.model} eliminad${this.pronombre} correctamente.`
        );
        this.close();
        this.reset();
        this.getData();
      },
      (err) => {
        this._toast.errorToastr(err, "Error");
      }
    );
  }

  //Utils
  onSelectedRow(event: any) {
    this.selectedRows = event.selected;
    if (this.selectedRows && this.selectedRows.length)
      this.selectedRow = this.selectedRows[0];
  }

  invalidControl(control: AbstractControl) {
    if (!control) return;
    return control.invalid && control.touched;
  }

  getPronombre(plural?: boolean) {
    if (this.pronombre === "a") {
      if (plural) return "las";
      else return "la";
    }
    if (this.pronombre === "o") {
      if (plural) return "los";
      else return "el";
    }
    return "";
  }

  cleanObj(obj: any) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null) {
        delete obj[key];
      }
    });
    return obj;
  }
}
