import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Globals } from "./../globals";
import { Router } from "@angular/router";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class InventarioService {
  constructor(
    private http: HttpClient,
    private globals: Globals,
    private router: Router
  ) {}

  getBitacora(filterObj) {
    return this.http
      .get(`${this.globals.apiUrl}/inventario/getBitacora`, {
        params: {
          filters: filterObj,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getBitacoraExcel(filters?: any) {
    return this.http.get<Blob>(
      `${this.globals.apiUrl}/inventario/getBitacoraExcel`,
      {
        params: { filters },
        responseType: "blob" as "json",
      }
    );
  }

  getBitacoraProduccion(filterObj) {
    return this.http
      .get(`${this.globals.apiUrl}/inventario/getBitacoraProduccion`, {
        params: {
          filters: filterObj,
        },
      })
      .pipe(catchError(this.handleError));
  }

  agregarEntrada(entrada) {
    return this.http
      .post(`${this.globals.apiUrl}/inventario/entrada`, {
        entrada: entrada,
      })
      .pipe(catchError(this.handleError));
  }

  getTotales(filterObj) {
    return this.http
      .get(`${this.globals.apiUrl}/inventario/getTotales`, {
        params: {
          filters: filterObj,
        },
      })
      .pipe(catchError(this.handleError));
  }

  getDetalleInventario(filterObj) {
    return this.http
      .get(`${this.globals.apiUrl}/inventario/getDetalleInventario`, {
        params: {
          filters: filterObj,
        },
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
