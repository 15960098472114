import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Globals } from './../globals';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AlmacenesService {

  constructor(
    private http: HttpClient,
    private globals: Globals
  ) { }

  getAll(){
    return this.http.get(`${this.globals.apiUrl}/almacenes/getAll`).pipe(catchError(this.handleError));
  }

  agregar(almacen) {
    return this.http.post(`${this.globals.apiUrl}/almacenes/add`, {
      almacen: almacen
    }).pipe(catchError(this.handleError));
  }

  editar(almacen){
    return this.http.post(`${this.globals.apiUrl}/almacenes/edit`, {
      almacen: almacen
    }).pipe(catchError(this.handleError));
  }

  eliminar(id){
    return this.http.post(`${this.globals.apiUrl}/almacenes/delete`, {
      id: id
    }).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
        console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
