import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Globals } from './../globals';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  constructor(
  	private http: HttpClient,
    private globals: Globals
  ) { }

  getAll(){
    return this.http.get(`${this.globals.apiUrl}/productos/getAll`).pipe(catchError(this.handleError));
  }

  getProductosDashboard(){
    return this.http.get(`${this.globals.apiUrl}/productos/getProductosDashboard`).pipe(catchError(this.handleError));
  }

  getById(id){
    return this.http.get(`${this.globals.apiUrl}/productos/getById`, {
      params: {
        id: id
      }
    }).pipe(catchError(this.handleError));
  }

  getLogosYcintillas() {
    return this.http.get(`${this.globals.apiUrl}/productos/getLogosYcintillas`).pipe(catchError(this.handleError));
  }

  getCategoriasLogos() {
    return this.http.get(`${this.globals.apiUrl}/productos/getCategoriasLogos`).pipe(catchError(this.handleError));
  }

  getCategoriasCintillas() {
    return this.http.get(`${this.globals.apiUrl}/productos/getCategoriasCintillas`).pipe(catchError(this.handleError));
  }

  agregar(producto) {
    return this.http.post(`${this.globals.apiUrl}/productos/add`, {
      producto: producto
    }).pipe(catchError(this.handleError));
  }

  addAndReturnRows(producto) {
    return this.http.post(`${this.globals.apiUrl}/productos/addAndReturnRows`, {
      producto: producto
    }).pipe(catchError(this.handleError));
  }

  editar(producto){
    return this.http.post(`${this.globals.apiUrl}/productos/edit`, {
      producto: producto
    }).pipe(catchError(this.handleError));
  }

  eliminar(id){
    return this.http.post(`${this.globals.apiUrl}/productos/delete`, {
      id: id
    }).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
        console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
